<template>
  <div class="soon" :style="`background-image: url('${sSrc}')`" v-if="sSrc">
    <div class="relative z-10 flex flex-col justify-center items-center">
      <img src="@/assets/images/logo.png" class="logo" />
      <h1 class="my-4">Estamos trabajando en nuestro nuevo sitio web</h1>
      <div>
        <a href="tel:+59899756175" class="mr-1">
          <i class="fab fa-whatsapp"></i>
          099.756.175
        </a>
        <a href="mailto:info@planetadeleste.com" class="ml-1">
          <i class="fas fa-envelope"></i>
          info@planetadeleste.com
        </a>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import _ from "lodash";

@Options({})
export default class App extends Vue {
  obReq!: __WebpackModuleApi.RequireContext;
  sSrc: string | null = null;
  arSrc: string[] = [];

  mounted() {
    this.obReq = require.context(
      "@/assets/images/bgs",
      false,
      /\.(jpe?g|tiff?|png|webp)$/
    );

    this.srcBg();
  }

  srcBg(): void {
    if (!this.obReq) {
      return;
    }

    if (_.isEmpty(this.arSrc)) {
      this.arSrc = this.obReq.keys();
    }

    const sKey = _.chain(this.arSrc)
      .shuffle()
      .first()
      .value();

    const iKey = _.indexOf(this.arSrc, sKey);
    
    if (iKey !== -1) {
      this.arSrc.splice(iKey, 1);
    }

    this.sSrc = this.obReq(sKey);

    _.delay(this.srcBg, 10000);
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/lbdrywall";
</style>
