
import { Options, Vue } from "vue-class-component";
import _ from "lodash";

@Options({})
export default class App extends Vue {
  obReq!: __WebpackModuleApi.RequireContext;
  sSrc: string | null = null;
  arSrc: string[] = [];

  mounted() {
    this.obReq = require.context(
      "@/assets/images/bgs",
      false,
      /\.(jpe?g|tiff?|png|webp)$/
    );

    this.srcBg();
  }

  srcBg(): void {
    if (!this.obReq) {
      return;
    }

    if (_.isEmpty(this.arSrc)) {
      this.arSrc = this.obReq.keys();
    }

    const sKey = _.chain(this.arSrc)
      .shuffle()
      .first()
      .value();

    const iKey = _.indexOf(this.arSrc, sKey);
    
    if (iKey !== -1) {
      this.arSrc.splice(iKey, 1);
    }

    this.sSrc = this.obReq(sKey);

    _.delay(this.srcBg, 10000);
  }
}
